.unorderedList {
    @apply space-y-050;

    list-style: none;

    & li {
        padding-left: theme('spacing.150');
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: theme('spacing.050');
            height: theme('spacing.050');
            background: theme('colors.layers.soft');
            border-radius: 50%;
            top: calc((calc(16px * 1.6) - 8px) / 2);
        }
    }
}

.orderedList {
    @apply space-y-050;

    list-style: none;
    counter-reset: order;

    & li {
        padding-left: theme('spacing.150');
        position: relative;
        counter-increment: order;

        &:before {
            content: counter(order) '.';
            position: absolute;
            left: 0;
        }
    }
}
