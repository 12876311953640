.list {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.075');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;

        margin-top: theme('spacing.200');
    }

    & > div {
        padding-top: theme('spacing.100');
        border-top: 1px solid theme('colors.decorators.subtle');

        &:nth-child(-n+2) {
            @media (min-width: theme('screens.lg')) {
                border: none;
            }
        }
    }

    dt,
    dd {
        display: block;
    }
}
