.saveForLaterButton {
    @apply type-paragraph type-080 type-link;

    display: block;
    margin: 0 auto;
    vertical-align: top;
    padding: theme('spacing.050');
}

.basketSummary {
    flex-shrink: 0;
    border-top: theme('border.light');
    background: theme('colors.neutrals.white');
}

.freeDeliveryNotice {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('spacing.025');
    padding: theme('spacing.050') theme('spacing.100');
    background-color: theme('colors.status.info.light');
    border-radius: theme('borderRadius.100');
}
